<template>
<div class="container mt-5">
    <div class="card">
        <div class="card-header">
            Potvrzování firmy
        </div>
        <div class="card-body">
            <div v-if="stav==1">
                Probíhá potvrzování
            </div>

            <div v-if="stav==2">
                <p>Potvrzeno</p>
                <p>firma: <b>{{c.name}}</b></p>
            </div>

            {{ errorMessage  }}
        </div>
    </div>
</div>
</template>

<script>
    const axios = require("axios");
    export default {
        name: "AcceptCompany",
        data(){
            return{
                stav: 1,
                c:[],
                errorMessage:''
            }
        },
        methods:{

        },
        mounted(){
            if (this.$route.params.token && this.$route.params.token.length>10){

                axios.get(process.env.VUE_APP_API_ROOT_URL + `/accept-company-token/${this.$route.params.token}`).then(result => {
                    if (result.status == 200) {
                        this.c = result.data
                        this.stav=2
                    }
                }).catch(err => {
                    this.stav=3
                    if (err.response.data) {
                        this.errorMessage = err.response.data
                    }
                });



            }
        }
    }
</script>

<style scoped>

</style>
