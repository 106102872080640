import { render, staticRenderFns } from "./AcceptCompany.vue?vue&type=template&id=6bba8cf8&scoped=true&"
import script from "./AcceptCompany.vue?vue&type=script&lang=js&"
export * from "./AcceptCompany.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bba8cf8",
  null
  
)

export default component.exports